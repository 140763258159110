import * as React from "react";
import styled from "@emotion/styled";
import { Icon } from "react-icons-kit";
import { starFull } from "react-icons-kit/icomoon/starFull";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { WebImage } from "../../lib/components/WebImage";
import { useConfig } from "@fitplan/context/lib-es/config";

interface Props {}

export const TestimonialSection: React.FunctionComponent<Props> = ({}) => {
  const { imageBaseUrl } = useConfig();
  const { t } = useTranslation();
  return (
    <Container>
      <Area area="img1">
        <WebImage
          src={`${imageBaseUrl}/web/subscribe/female-transformation.png`}
          transformation={{ width: 380 }}
        />
      </Area>
      <Area area="img2">
        <WebImage
          src={`${imageBaseUrl}/web/subscribe/male-transformation.png`}
          transformation={{ width: 380 }}
        />
      </Area>
      <Area area="t1">
        <Stars />
        <Testimonial>
          {t("tst1")}
        </Testimonial>
        <Name>— {t("tst1Name")}</Name>
      </Area>
      <Area area="t2">
        <Stars />
        <Testimonial>
          {t("tst2")}
        </Testimonial>
        <Name>— {t("tst2Name")}</Name>
      </Area>
      <Area area="t3">
        <Stars />
        <Testimonial>
          {t("tst3")}
        </Testimonial>
        <Name>— {t("tst3Name")}</Name>
      </Area>
    </Container>
  );
};

const breakpoint = "700px";

const Container = styled.div`
  display: grid;
  width: 100%;
  max-width: 1200px;
  margin: 16px auto;

  grid-template-areas:
    "img1" "img2"
    "t1" "t2" "t3";
  grid-template-columns: 1fr;
  grid-template-rows: 380 380 auto auto auto;
  grid-gap: 8px;
  align-content: center;
  justify-content: center;

  @media screen and (min-width: ${breakpoint}) {
    grid-template-areas:
      ". img1 img1 img2 img2 ."
      "t1 t1 t2 t2 t3 t3";
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 380px auto;
    grid-gap: 16px;
  }
`;

interface ElementProps {
  area: string;
}

const Area = styled.div<ElementProps>`
  grid-area: ${(props) => props.area};
  margin: auto;
  @media screen and (min-width: ${breakpoint}) {
    margin: 0;
  }
`;

const StarContainer = styled.div`
  text-align: center;
  margin-bottom: 8px;
  color: #12b587;
`;

const Stars = () => (
  <StarContainer>
    <Icon icon={starFull} />
    <Icon icon={starFull} />
    <Icon icon={starFull} />
    <Icon icon={starFull} />
    <Icon icon={starFull} />
  </StarContainer>
);

const Testimonial = styled.div`
  font-family: Barlow, sans-serif;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: normal;
  text-align: center;
  color: #111213;
`;

const Name = styled(Testimonial)`
  font-size: 12px;
  text-transform: uppercase;
`;
