import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { localeTextGetter } from "../../lib/l10n";
import { stripeProductId } from "../../config";
import { IStripePlan, IStripeCoupon } from "@fitplan/lib/types";

import "./subscribeButtons.scss";
import { SubscribeSection } from "./SubscribeSection";
// import { useState } from "react";
// import useMount from "react-use/lib/useMount";
import { IPromoData } from "pages/subscribe";

export interface Props {
  title?: string;
  subtitle?: string;
  mobileImageSrc?: string;
  allStripePlans: object;
  filter?: (plan: IStripePlan) => boolean;
  newCustomersOnly?: React.ReactNode;

  couponCode?: string;
  promoData?: IPromoData;
}

export const Subscribe: React.SFC<Props> = ({
  promoData,
  couponCode,
  allStripePlans,
  mobileImageSrc,
  subtitle,
  title,
  filter,
  newCustomersOnly,
  children,
  ...props
}) => {
  /*
  const data = {
    allStripePlans: {
      edges: [
        {
          node: {
            name: "Web Monthly US",
            created: 1582066409,
            product: "prod_GlAewRzw6PADAg",
            interval: "month",
            interval_count: 1,
            trial_period_days: 7,
            usage_type: "licensed",
            active: true,
            currency: "usd",
            amount: 1999,
            planId: "plan_GlAgLEftz9YZLL",
          },
        },
        {
          node: {
            name: "Web Annual US",
            created: 1582066409,
            product: "prod_GlAewRzw6PADAg",
            interval: "year",
            interval_count: 1,
            trial_period_days: 7,
            usage_type: "licensed",
            active: true,
            currency: "usd",
            amount: 6999,
            planId: "plan_GlAgPbspL4OrHo",
          },
        },
      ],
    },
  };
  */

  // const { allStripePlans } = React.useMemo(
  //   () => localeTextGetter({allStripePlansOb}),
  //   [{allStripePlansOb}]
  // );

  const data = useStaticQuery(graphql`
  query Subscribe {
      allStripePlans(
          filter: { amount: { gt: 0 }, interval: { ne: "onetime" } }
      ) {
          edges {
              node {
                  name: nickname
                  created
                  product
                  interval
                  interval_count
                  trial_period_days
                  usage_type
                  active
                  currency
                  amount
                  planId
              }
          }
      }
  }
`);
  // const { allStripePlans } = React.useMemo(() => localeTextGetter(data), [
  //   data,
  // ]);
  // const planData = allStripePlans.edges.map((edge: any) => edge.node);
  const planData = data.allStripePlans.edges.map((edge: any) => edge.node);
  return (
    <SubscribeSection
      stripeProductId={stripeProductId}
      stripePlans={planData}
      promoData={promoData}
      title={title}
      subtitle={subtitle}
      allStripePlans={data.allStripePlans}
      mobileImageSrc={mobileImageSrc}
      couponCode={couponCode}
      filter={filter || selectPlans}
      newCustomersOnly={newCustomersOnly}
    >
      {children}
    </SubscribeSection>
  );
};

const interval = (plan: IStripePlan & { intervalCount?: number }) =>
  plan.interval_count || plan.intervalCount;
const selectPlans = (plan: IStripePlan) => {
  return (
    (plan.interval === "year" && interval(plan) === 1) ||
    (plan.interval === "month" && interval(plan) === 1)
  );
};
