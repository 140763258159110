import * as React from "react";
import styled from "@emotion/styled";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { useConfig } from "@fitplan/context/lib-es/config";
import { WebImage } from "../WebImage";

export interface IFeature {
  title: string;
  subtitle: string;
  img: string | { src: string; base64: string };
}

export interface Props {
  // features: [IFeature, IFeature, IFeature];
}

export const FeatureImageSection: React.FunctionComponent<Props> = ({}) => {
  const { imageBaseUrl } = useConfig();
  const { t } = useTranslation();
  return (
    <Container imageBaseUrl={imageBaseUrl}>
      <FeatureContainer isOdd={false}>
        <Text isOdd={false}>
          <Title>{t("appb1Heading")}</Title>
          <Subtitle>{t("appb1Desc")}</Subtitle>
        </Text>
        <ImageBox isOdd={false}>
          <WebImage
            src={`/images/subscribe/screenshots/right-mockup.png`}
            transformation={{ width: 405 }}
            css={css`
              width: 100%;
            `}
          />
        </ImageBox>
      </FeatureContainer>
      <FeatureContainer isOdd={true}>
        <Text isOdd={true}>
          <Title>{t("appb2Heading")}</Title>
          <Subtitle>{t("appb2Desc")}</Subtitle>
        </Text>
        <ImageBox isOdd={true}>
          <WebImage
            src={`/images/subscribe/screenshots/left-mockup.png`}
            transformation={{ width: 405 }}
            css={css`
              width: 100%;
            `}
          />
        </ImageBox>
      </FeatureContainer>
      <FeatureContainer isOdd={false}>
        <Text isOdd={false}>
          <Title>{t("appb3Heading")}</Title>
          <Subtitle>{t("appb3Desc")}</Subtitle>
        </Text>
        <ImageBox isOdd={false}>
          <WebImage
            src={`${imageBaseUrl}/web/subscribe/screenshots/results-apple-watch.png`}
            transformation={{ width: 330 }}
            css={css`
              width: 100%;
            `}
          />
        </ImageBox>
      </FeatureContainer>
    </Container>
  );
};

const breakpoint = "700px";

const Container = styled.div<{ imageBaseUrl: string }>`
  width: 100%;
  box-sizing: border-box;
  padding: 16px;

  background: url(${(props) =>
      props.imageBaseUrl}/web/subscribe/rectangle-mobile.png?tr=w-700)
    no-repeat center center;
  background-size: contain;

  @media screen and (min-width: ${breakpoint}) {
    background: url(${(props) =>
        props.imageBaseUrl}/web/subscribe/rectangle-desktop.png?tr=w-1200)
      no-repeat center center;
    background-size: contain;
  }
`;

interface FeatureProps {
  isOdd: boolean;
}

const FeatureContainer = styled.section<FeatureProps>`
  width: 100%;
  max-width: 900px;
  margin: auto;

  display: grid;

  grid-template-areas: ${(props) =>
    props.isOdd ? `"img ." "img text" "img ."` : `". img" "text img" ". img"`};
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr auto 1fr;

  margin-bottom: 16px;
`;

const ImageBox = styled.div<FeatureProps>`
  grid-area: img;

  justify-self: ${(props) => (props.isOdd ? `start` : `end`)};
`;

const Text = styled.div<FeatureProps>`
  grid-area: text;
  justify-self: ${(props) => (props.isOdd ? `start` : `end`)};
`;

const Title = styled.div`
  font-family: Barlow, sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #111213;

  @media screen and (min-width: ${breakpoint}) {
    font-size: 32px;
  }
`;

const Subtitle = styled(Title)`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;

  @media screen and (min-width: ${breakpoint}) {
    font-size: 21px;
  }
`;
