import * as React from "react";
import styled from "@emotion/styled";
import queryString from "query-string";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { FeatureImageSection } from "../../lib/components/FeatureImageSection";
import { SectionTitle } from "../../lib/components/Header/SectionTitle";
import Layout from "../../components/layout/layout";
import { useConfig } from "@fitplan/context/lib-es/config";
import "@fitplan/lib/components/LandingPage/plan.scss";
import { TestimonialSection } from "../../components/subscribe/TestimonialSection";
import { Subscribe } from "../../components/subscribe";
import { IStripePlan } from "../../lib/types";
import { NewSubscribeButtonBare } from "../../components/subscribe/NewSubscribeButton";
import useMount from "react-use/lib/useMount";
import { graphql } from "gatsby";
import "./subscribe.scss";

export interface Props {
  location: { search: string };
  data: {
    ribbon: object;
    header: object;
    footer: object;
    allStripePlans: object;
  };
}

export interface IPlanOverride {
  price?: number;
  hide?: boolean;
}

export interface IPromoData {
  coupon?: string;
  showModal?: boolean;
  backgroundUrl?: string;
  mobileBackgroundUrl?: string;
  override: {
    yearly?: IPlanOverride;
    monthly?: IPlanOverride;
  };
}

const getQuery = (search: string): any => {
  const initQuery = queryString.parse(search);
  return Object.entries(initQuery).reduce(
    (acc, [key, value]: [string, string]) => {
      return Object.assign(acc, {
        [key]: value,
      });
    },
    {}
  );
};

export const query = graphql`
  query ($languageISO: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allStripePlans {
      edges {
        node {
          id
          currency
          interval
          intervalCount
          nickname
          object
          planId
          product
          trialPeriodDays
          usageType
        }
      }
    }
    header: contentfulModules(
      type: { eq: "Header" }
      node_locale: { eq: $languageISO }
    ) {
      id
      items {
        ... on ContentfulItems {
          id
          link
          linkText
        }
      }
    }
    ribbon: contentfulModules(
      type: { eq: "Ribbon" }
      node_locale: { eq: $languageISO }
    ) {
      id
      heading
    }
    footer: allContentfulModules(
      filter: { type: { eq: "Footer" }, node_locale: { eq: $languageISO } }
    ) {
      nodes {
        id
        heading
        items {
          ... on ContentfulItems {
            id
            link
            linkText
          }
        }
      }
    }
  }
`;

const SubscribePage: React.SFC<Props> = (props) => {
  const header = props.data.header;
  const ribbon = props.data.ribbon;
  const footer = props.data.footer;
  const allStripePlans = props.data.allStripePlans;
  const { coupon: couponCode, promo } = getQuery(props.location.search);
  const [promoData, setPromoData] = React.useState<IPromoData | null>(null);
  useMount(() => {
    try {
      promo && setPromoData(JSON.parse(atob(decodeURI(promo))));
    } catch (err) {
      console.log("Promo error", err);
    }
  });
  const { imageBaseUrl } = useConfig();
  let filter = null;
  if (promoData) {
    filter = (plan: IStripePlan) => {
      if (plan.interval === "month" && promoData.override?.monthly?.hide) {
        return false;
      }
      if (plan.interval === "year" && promoData.override?.yearly?.hide) {
        return false;
      }
      return true;
    };
  }
  let children = null;
  if (promoData?.coupon) {
    children = (
      <NewSubscribeButtonBare
        title="EXISTING MEMBERS"
        price="$4.16"
        interval="mo"
        footer="Renew for just $50"
        text="RENEW AND SAVE"
        onClick={() => (window.location.href = "/giftcard/summer")}
      />
    );
  }
  const { t } = useTranslation();
  return (
    <Layout
      header={header}
      ribbon={ribbon}
      footer={footer}
      hasBanner="white-banner"
    >
      <div className="subscribe cms-plan-view">
        <Subscribe
          allStripePlans={allStripePlans}
          promoData={promoData}
          couponCode={
            couponCode?.toUpperCase() || promoData?.coupon?.toUpperCase()
          }
          filter={filter}
          mobileImageSrc={promoData?.mobileBackgroundUrl}
          title={promoData?.coupon ? "Plan for Summer" : undefined}
          subtitle={
            promoData?.coupon
              ? "Get or renew a Fitplan subscription for just $50 and start making plans for Summer '21 today!"
              : undefined
          }
        >
          {children}
        </Subscribe>
        <TestimonalBox>
          <SectionTitle>{t("tstHeading")}</SectionTitle>
          <TestimonialSection />
        </TestimonalBox>
        <div style={{ marginTop: "32px", marginBottom: "16px" }}>
          <SectionTitle>{t("appSecHeading")}</SectionTitle>
        </div>
        <FeatureImageSection />
      </div>
    </Layout>
  );
};

const TestimonalBox = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding-top: 60px;
  padding-bottom: 48px;

  background-color: #f0f0f0;
`;

export default SubscribePage;
